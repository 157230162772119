import { territoryColorMap } from 'theme/colors';
import { useEffect, useRef, useState } from 'react';

export default function AssociatedTerritoryUser({ editingMode, setEditingMode, employees }) {
  const [open, setOpen] = useState(false);
  const componentRef = useRef();

  const changeAssociatedUser = (employee) => {
    setEditingMode((prev) => ({
      ...prev,
      current: {
        ...prev?.current,
        user: {
          color: { ...employee.color, value: territoryColorMap[employee.color.label] }, // replace color value with a transparent version
          firstName: employee.firstName,
          lastName: employee.lastName,
        },
      },
    }));
  };

  const close = (e) => {
    if (open && !componentRef.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('mousedown', close);
    return () => window.removeEventListener('mousedown', close);
  });

  return (
    <div className='absolute left-64 top-[8px] bg-white z-10 rounded-sm' ref={componentRef}>
      {/* main button */}
      {!!editingMode?.current && (
        <button
          onClick={() => {
            setOpen((p) => !p);
          }}
          className='font-semibold hover:cursor-pointer px-[17px] w-48 hover:bg-gray-200 h-8 flex items-center drop-shadow'
        >
          <div className={`w-4 h-4 mr-2`} style={{ background: editingMode?.current?.user?.color?.value }}></div>
          <p className={'text-ellipsis text-nowrap overflow-hidden mx-auto'}>
            {typeof editingMode?.current?.user?.firstName !== 'string' ||
            typeof !editingMode?.current?.user?.lastName !== 'string'
              ? editingMode?.current?.user?.firstName + ' ' + editingMode?.current?.user?.lastName
              : 'unkown user'}
          </p>
        </button>
      )}
      {/* list */}
      {open &&
        employees?.map((employee) => {
          // if employee is the one currently selected dont show it
          if (
            employee?.firstName + employee?.lastName ===
            editingMode?.current?.user?.firstName + editingMode?.current?.user?.lastName
          )
            return <></>;
          return (
            <button
              onClick={() => {
                changeAssociatedUser(employee);
                setOpen(false);
              }}
              key={'employee: ' + employee.id}
              className='hover:cursor-pointer px-[17px] hover:bg-gray-200 w-48 flex items-center drop-shadow'
            >
              <div className={`w-4 h-4 mr-2`} style={{ background: territoryColorMap[employee.color.label] }}></div>
              <p className='text-ellipsis text-nowrap overflow-hidden mx-auto'>
                {employee.firstName + ' ' + employee.lastName}
              </p>
            </button>
          );
        })}
    </div>
  );
}

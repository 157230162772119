// InputMUI.js
import React from 'react';
import { TextField } from '@mui/material';

export const inputStyles = {
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#111827',
    cursor: 'not-allowed',
  },
  '& .MuiFilledInput-root': {
    fontFamily: 'inherit',
    backgroundColor: '#f9fafb',
    color: '#111827',
    '&:hover': {
      backgroundColor: '#f9fafb',
    },
    '&.Mui-focused': {
      backgroundColor: '#f9fafb', // Keep the background color gray-50 when focused
    },
    '&:before': {
      borderBottomColor: '#6b7280',
    },
    '&:after': {
      borderBottomColor: '#fef08a',
    },
    '&.Mui-focused:after': {
      borderBottomColor: '#fef08a',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#f9fafb', // Ensure disabled background color matches enabled state
      color: '#111827', // Ensure disabled text color matches enabled state
      WebkitTextFillColor: '#111827 !important', // Change this line
      // "-webkit-text-fill-color": "#111827 !important", // Override default disabled text color
      '&:before': {
        borderBottomStyle: 'solid',
        borderBottomColor: '#6b7280',
      },
    },
  },
  '& .MuiInputLabel-root': {
    fontFamily: 'inherit',
    color: '#6b7280',
    '&.Mui-focused': {
      color: '#6b7280',
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      color: '#6b7280', // Ensure disabled label color matches enabled state
    },
  },
};

const InputMUI = React.forwardRef(({ value, setValue, label, onChangeFunction = null, sx, ...props }, ref) => {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      value={value}
      onChange={onChangeFunction || handleChange}
      label={label}
      variant='filled'
      type={props.type || 'text'}
      fullWidth
      inputRef={ref} // Pass the ref to the underlying input element
      sx={{
        ...inputStyles,
        ...sx, // custom styles
      }}
      {...props}
    />
  );
});

export default InputMUI;

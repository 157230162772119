import React, { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../index";
import MapView from "./Components/MapView";
import useCustomers from "./Components/hooks/useCustomers";
import useMarkers from "./Components/hooks/useMarkers";
import { localData } from "./helpers";
import { LoadingModal } from "../../components/LoadingModal/LoadingModal";
import LoadingIndicator from "./Components/LoadingIndicator";
import useLocation from "./Components/hooks/useLocation";
import Controls from "./Components/Controls";
import FilterPanel from "./Components/panels/FilterPanel";
import useTerritories from "./Components/hooks/useTerritories";
import { useEmployeeContext } from "context/EmployeeContext";
import AssociatedTerritoryUser from "./Components/lowerLevelComponents/AssociatedTerritoryUser";
import AddressSearch from "./Components/AddressSearch";
import MarkerPanel from "./Components/panels/MarkerPanel";
import useErrorToasts from "./Components/hooks/useErrorToasts";
import CustomerPanel from "./Components/panels/CustomerPanel";
import CurrentLocationButton from "./Components/CurrentLocationButton";
import MultipleCustomersPanel from "./Components/panels/MultipleCustomersPanel";
import StatsPanel from "./Components/panels/StatsPanel";
import { useJsApiLoader } from "@react-google-maps/api";
import useCustomerLasso from "./Components/hooks/useCustomerLasso";
import usePanel from "./Components/hooks/usePanel";

const libraries = ["places"];

export default function TerritoryMap() {
  const { userData, bannerVisible } = useContext(UserContext);
  const { errorToastRetry, errorToast } = useErrorToasts();
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { employees } = useEmployeeContext();
  const currentTerritoryRef = useRef(null);
  const [editingMode, setEditingMode] = useState({
    // state for editing map data, like territories
    operation: "", // (addingTerritory, editingTerritory, selectingCustomers, displayingCustomers)
    newCoordinates: null,
    current: null,
    previous: null,
  });
  const [dataToDisplay, setDataToDisplay] = useState({
    type: "markers",
    dateRange: localData.dateRanges[0],
    uidToFilterBy: null,
  });
  const { territories, updateTerritories } = useTerritories();
  const { customers } = useCustomers({
    dataToDisplay,
    setShowLoadingIndicator,
  });
  const { location, setLocation } = useLocation();
  const { markers, updateMarker } = useMarkers({
    dataToDisplay,
    setShowLoadingIndicator,
    location,
    errorToastRetry,
    errorToast,
  });

  // const [panelVisible, setPanelVisible] = useState({ type: '', data: null });
  const {
    panelVisible,
    setPanelVisible,
    confirmChangePanel,
    showPanelConfirmDialogue,
  } = usePanel();

  const [addressSearched, setAddressSearched] = useState({
    address: null,
    position: null,
  }); // updating this value will cause the map center to move
  const [map, setMap] = useState();
  const [userLocation, setUserLocation] = useState(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
    libraries,
  });
  const { startLassoingCustomers, stopLassoingCustomers } = useCustomerLasso({
    map,
    editingMode,
    setEditingMode,
    setPanelVisible,
    customers,
    errorToast,
    panelVisible,
  });

  return (
    <div
      style={{
        height: bannerVisible
          ? `calc(100vh - 64px - 56px)`
          : `calc(100vh - 64px)`,
      }}
      className={`flex flex-row relative`}
    >
      {/* panels */}
      <MultipleCustomersPanel
        panelVisible={panelVisible}
        setPanelVisible={setPanelVisible}
        confirmChangePanel={confirmChangePanel}
        showPanelConfirmDialogue={showPanelConfirmDialogue}
      />
      <FilterPanel
        setDataToDisplay={setDataToDisplay}
        visible={panelVisible?.type === "filters"}
        closeFunction={() => {
          setPanelVisible({ type: "", data: null });
        }}
        employees={employees}
        dataToDisplay={dataToDisplay}
      />
      <StatsPanel
        errorToast={errorToast}
        setPanelVisible={setPanelVisible}
        panelVisible={panelVisible}
        employees={employees}
      />
      <MarkerPanel
        closeFunction={() => {
          setPanelVisible({ type: "", data: null });
        }}
        panelVisible={panelVisible}
        updateMarker={updateMarker}
        setPanelVisible={setPanelVisible}
        employees={employees}
      />
      <CustomerPanel
        setPanelVisible={setPanelVisible}
        panelVisible={panelVisible}
      />

      <AssociatedTerritoryUser
        editingMode={editingMode}
        setEditingMode={setEditingMode}
        employees={employees}
      />
      <Controls
        setPanelVisible={setPanelVisible}
        editingMode={editingMode}
        setEditingMode={setEditingMode}
        territories={territories}
        updateTerritories={updateTerritories}
        dataToDisplay={dataToDisplay}
        customers={customers}
        errorToast={errorToast}
      />
      <LoadingIndicator visible={showLoadingIndicator} />
      {isLoaded && (
        <AddressSearch
          editingMode={editingMode}
          addressSearched={addressSearched}
          setAddressSearched={setAddressSearched}
          map={map}
        />
      )}
      {isLoaded && (
        <CurrentLocationButton
          errorToast={errorToast}
          setUserLocation={setUserLocation}
          map={map}
        />
      )}
      <MapView
        setLocation={setLocation}
        bannerVisible={bannerVisible}
        userData={userData}
        customers={customers}
        location={location}
        dataToDisplay={dataToDisplay}
        markers={markers}
        territories={territories}
        editingMode={editingMode}
        setEditingMode={setEditingMode}
        currentTerritoryRef={currentTerritoryRef}
        addressSearched={addressSearched}
        setAddressSearched={setAddressSearched}
        setPanelVisible={setPanelVisible}
        panelVisible={panelVisible}
        map={map}
        setMap={setMap}
        userLocation={userLocation}
        setUserLocation={setUserLocation}
        errorToast={errorToast}
        isLoaded={isLoaded}
        startLassoingCustomers={startLassoingCustomers}
        stopLassoingCustomers={stopLassoingCustomers}
      />
    </div>
  );
}

import { db } from 'utils/firebase';
import {
  doc,
  setDoc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  getDoc,
  arrayRemove,
  writeBatch,
  arrayUnion,
  onSnapshot,
} from 'firebase/firestore';
import { uploadFile } from 'utils/uploadFile';

export async function checkForDuplicateCustomer(userData, newCustomerData) {
  if (newCustomerData.phone.mobile !== '') {
    const customerCollectionRef = collection(db, 'businesses', userData.userData.businessId, 'customers');
    const querySnapshot = await getDocs(
      query(customerCollectionRef, where('phone.mobile', '==', newCustomerData.phone.mobile))
    );
    if (querySnapshot.docs.length > 0) {
      return querySnapshot.docs[0].data();
    }
  }
  return null;
}

// don't need to export this one
async function handleAttachments(newCustomerData, existingAttachments = []) {
  const attachmentObjectsWithStorageUrls = [...existingAttachments];
  if (newCustomerData?.attachments?.length > 0) {
    for (const localFile of newCustomerData.attachments) {
      const url = await uploadFile(localFile.file, 'customer-attachments');
      const newAttachmentObject = {
        url: url,
        name: localFile.name,
      };
      attachmentObjectsWithStorageUrls.push(newAttachmentObject);
    }
  }
  return attachmentObjectsWithStorageUrls;
}

async function uploadAttachments(newAttachments, existingAttachments = []) {
  const attachmentObjectsWithStorageUrls = [...existingAttachments];
  if (newAttachments?.length > 0) {
    for (const localFile of newAttachments) {
      const url = await uploadFile(localFile.file, 'customer-attachments');
      const newAttachmentObject = {
        url: url,
        name: localFile.name,
      };
      attachmentObjectsWithStorageUrls.push(newAttachmentObject);
    }
  }
  return attachmentObjectsWithStorageUrls;
}

export async function saveCustomerData(userData, newCustomerData) {
  const newCustomerRef = doc(collection(db, 'businesses', userData.userData.businessId, 'customers'));

  const attachmentObjectsWithStorageUrls = await handleAttachments(newCustomerData);

  const customerDataToSave = {
    address: newCustomerData.address,
    businessId: userData.userData.businessId,
    customerId: newCustomerRef.id,
    dateAdded: serverTimestamp(),
    displayName: newCustomerData.displayName,
    email: newCustomerData.email,
    firstName: newCustomerData.firstName,
    lastName: newCustomerData.lastName,
    lastUpdated: serverTimestamp(),
    notes: newCustomerData.notes,
    notifications: newCustomerData.notifications,
    phone: newCustomerData.phone,
    tags: newCustomerData.tags,
    attachments: attachmentObjectsWithStorageUrls,
  };

  await setDoc(newCustomerRef, customerDataToSave);
  return customerDataToSave;
}

export async function mergeCustomerData(userData, duplicateCustomer, newCustomerData) {
  const attachmentObjectsWithStorageUrls = await handleAttachments(
    newCustomerData,
    duplicateCustomer?.attachments || []
  );

  const customerCollectionRef = collection(db, 'businesses', userData.userData.businessId, 'customers');

  const duplicateCustomerRef = doc(customerCollectionRef, duplicateCustomer.customerId);

  const updatedCustomerDataWithAttachments = {
    address: newCustomerData.address,
    displayName: newCustomerData.displayName,
    email: newCustomerData.email,
    firstName: newCustomerData.firstName,
    lastName: newCustomerData.lastName,
    lastUpdated: serverTimestamp(),
    notes: newCustomerData.notes,
    notifications: newCustomerData.notifications,
    phone: newCustomerData.phone,
    tags: newCustomerData.tags,
    attachments: attachmentObjectsWithStorageUrls,
  };

  await updateDoc(duplicateCustomerRef, updatedCustomerDataWithAttachments);
  return updatedCustomerDataWithAttachments;
}

export async function updateCustomerData(userData, newCustomerData, customerId) {
  try {
    const attachmentsWithStorageUrls = [];
    const newAttachments = [];
    if (newCustomerData?.attachments && newCustomerData?.attachments?.length > 0) {
      for (let attachment of newCustomerData.attachments) {
        if (attachment?.file) {
          newAttachments.push(attachment);
        } else if (attachment?.url) {
          attachmentsWithStorageUrls.push(attachment);
        }
      }
    }

    const combinedAttachments = await uploadAttachments(newAttachments, [...attachmentsWithStorageUrls]);

    const customerRef = doc(db, 'businesses', userData.userData.businessId, 'customers', customerId);

    const updatedCustomer = {
      address: newCustomerData.address,
      displayName: newCustomerData.displayName,
      email: newCustomerData.email,
      firstName: newCustomerData.firstName,
      lastName: newCustomerData.lastName,
      lastUpdated: serverTimestamp(),
      notes: newCustomerData.notes,
      notifications: newCustomerData.notifications,
      phone: newCustomerData.phone,
      tags: newCustomerData.tags,
      attachments: combinedAttachments,
    };

    await updateDoc(customerRef, updatedCustomer);

    // just making it whole again before returning data, so we can set it directly in state
    updatedCustomer.customerId = customerId;
    updatedCustomer.businessId = userData.userData.businessId;

    return updatedCustomer;
  } catch (err) {
    console.error('Error updating customer', err);
    throw err;
  }
}

export const updateSubscriptionTemplate = async ({ businessId, templateDetails }) => {
  try {
    const templateRef = doc(db, 'businesses', businessId, 'subscriptionTemplates', templateDetails.templateId);

    const updatedTemplate = {
      ...templateDetails,
      lastUpdated: new Date(),
    };

    await updateDoc(templateRef, updatedTemplate);
    return updatedTemplate;
  } catch (err) {
    throw err;
  }
};

export const saveNewSubscriptionTemplate = async ({ businessId, templateDetails }) => {
  try {
    const templateRef = doc(collection(db, 'businesses', businessId, 'subscriptionTemplates'));
    const templateId = templateRef.id;

    const templateDataToSave = {
      ...templateDetails,
      templateId,
      createdAt: new Date(),
      lastUpdated: new Date(),
    };

    await setDoc(templateRef, templateDataToSave);
    return templateDataToSave;
  } catch (err) {
    throw err;
  }
};

export const updateCustomerInSubscription = async ({ businessId, subscription, setSubscription }) => {
  if (!subscription.subscriptionId || !subscription.customer.customerId) {
    return;
  }

  console.log('Updating customer in subscription', subscription.subscriptionId);
  try {
    const subscriptionRef = doc(db, 'businesses', businessId, 'subscriptions', subscription.subscriptionId);
    const customerRef = doc(db, 'businesses', businessId, 'customers', subscription.customer.customerId);

    const customerSnap = await getDoc(customerRef);
    if (!customerSnap.exists()) {
      throw new Error('Customer not found');
    }

    const customerData = customerSnap.data();

    const updatedSubscription = {
      ...subscription,
      customer: customerData,
    };

    await updateDoc(subscriptionRef, updatedSubscription);

    setSubscription(updatedSubscription);
  } catch (error) {
    console.error('Error updating customer in subscription', error);
  }
};

export async function handleTemplateAttachments(attachments) {
  const attachmentObjectsWithStorageUrls = [];
  if (attachments?.length > 0) {
    for (const localFile of attachments) {
      if (localFile.url) {
        attachmentObjectsWithStorageUrls.push(localFile);
        continue;
      }
      const url = await uploadFile(localFile.file, 'template-attachments');
      const newAttachmentObject = {
        url: url,
        name: localFile.name,
      };
      attachmentObjectsWithStorageUrls.push(newAttachmentObject);
    }
  }
  return attachmentObjectsWithStorageUrls;
}

// export async function updateSubscriptionForJob({
//   businessId,
//   newJobRef,
//   subscription,
// }) {
//   if (
//     !subscription?.selectedSubscription?.subscriptionId ||
//     !subscription?.selectedVisit?.id
//   ) {
//     return;
//   }

//   console.log("updating subscription");

//   const subscriptionRef = doc(
//     db,
//     "businesses",
//     businessId,
//     "subscriptions",
//     subscription.selectedSubscription.subscriptionId
//   );

//   const updatedVisits = subscription.selectedSubscription.visits.map(
//     (visit) => {
//       // update selected visit
//       if (visit.id === subscription.selectedVisit.id) {
//         return {
//           ...visit,
//           status: "scheduled",
//           jobId: newJobRef.id,
//         };
//       }
//       // keep all the other visits the same
//       return visit;
//     }
//   );

//   // update subscription
//   await updateDoc(subscriptionRef, { visits: updatedVisits });
//   // update job
//   await updateDoc(newJobRef, {
//     subscription: {
//       selectedSubscription: subscription.selectedSubscription,
//       selectedVisit: subscription.selectedVisit,
//     },
//   });

//   // Return the updated subscription data
//   return {
//     ...subscription.selectedSubscription,
//     visit: {
//       ...subscription.selectedVisit,
//       status: "scheduled",
//       jobId: newJobRef.id,
//     },
//   };
// }

///await removeVisitFromJob({
//   businessId: selectedSubscription.businessId,
//   jobId: fromJobDetails,
//   visitId: subscription.selectedVisit.id,
// });

// export async function removeVisitFromJob({ businessId, jobId, visitId, subscriptionId }) {
//   const jobRef = doc(db, "businesses", businessId, "jobs", jobId);
//   const subRef = doc(db, "businesses", businessId, "subscriptions", subscriptionId);

//   // so we update the job first to remove the subscription data... we can just clear it out -- set to null
//   await updateDoc(jobRef, {
//     subscription: null,
//   });
//   // then we update the subscription to change the visit status to "unscheduled" and the jobId to null
//   // Fetch the current subscription document
//   const subscriptionSnap = await getDoc(subRef);

//   if (!subscriptionSnap.exists()) {
//     throw new Error(`Subscription ${subscriptionId} not found`);
//   }

//   const subscription = subscriptionSnap.data();

//   // Find the visit and modify only the necessary fields
//   const updatedVisits = subscription.visits.map((visit) => {
//     if (visit.id === visitId) {
//       return {
//         ...visit,
//         status: "unscheduled",
//         jobId: null,
//       };
//     }
//     return visit;
//   });

//   // Update the subscription with the modified visits array
//   await updateDoc(subRef, {
//     visits: updatedVisits,
//   });
// }

export const getAllEmployees = async (businessId) => {
  try {
    const employeeRef = collection(db, 'users');
    const employeeQuery = query(employeeRef, where('businessId', '==', businessId));
    const docSnap = await getDocs(employeeQuery);
    if (docSnap.empty) return [];
    const docArray = docSnap.docs.map((doc) => doc.data());
    const sortedArray = docArray.sort((a, b) => a.firstName.localeCompare(b.firstName));
    return sortedArray;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllTerritories = async (businessId) => {
  try {
    const territoriesRef = doc(db, 'businesses', businessId, 'maps', 'territoryMaps');
    return (await getDoc(territoriesRef)).get('territories') || [];
  } catch (error) {
    console.log('error in getAllTerritories: ', error);
    return [];
  }
};

// delete and/or add a territory
export const updateTerritoriesDB = async ({ newTerritory, territoryToDelete, businessId }) => {
  try {
    const territoriesRef = doc(db, 'businesses', businessId, 'maps', 'territoryMaps');
    const batch = writeBatch(db);

    if (territoryToDelete) {
      batch.set(
        territoriesRef,
        {
          territories: arrayRemove(territoryToDelete),
        },
        { merge: true }
      );
    }
    if (newTerritory) {
      batch.set(
        territoriesRef,
        {
          territories: arrayUnion(newTerritory),
        },
        { merge: true }
      );
    }
    await batch.commit();
  } catch (e) {
    console.log('error adding territory: ', e);
  }
};

export const updateMarkerDB = async ({ marker, businessId, userId }) => {
  const batch = writeBatch(db);
  // sync name/note changes to associated customer, if one exists. If customer has been deleted, remove association
  if (marker.customerId) {
    const customerRef = doc(db, 'businesses', businessId, 'customers', marker.customerId);
    const customerSnap = await getDoc(customerRef);
    if (customerSnap.exists())
      batch.update(customerRef, { firstName: marker.firstName, lastName: marker.lastName, notes: marker.notes });
    else marker = { ...marker, customerId: null };
  }
  // update marker
  const markerRef = doc(db, 'businesses', businessId, 'markers', marker.id);
  batch.update(markerRef, marker);
  batch.commit();
};

import { GoogleMap, useJsApiLoader, Marker, InfoWindow, MarkerF, Polygon, InfoWindowF } from '@react-google-maps/api';
import React, { useContext, useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { db } from 'utils/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { debounce } from 'lodash';
import { convertTerritoryFormat, deltasPointsConverter, getIcon, markerSVGs } from '../helpers';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import hollowCircle from '../../../svg/hollowCircle.svg';
import markerClusterSVG from 'svg/markerCluster.svg';

export default function MapView({
  userData,
  customers,
  location,
  setLocation,
  dataToDisplay,
  markers,
  territories,
  setEditingMode,
  editingMode,
  addressSearched,
  setAddressSearched,
  setPanelVisible,
  panelVisible,
  map,
  setMap,
  userLocation,
  setUserLocation,
  errorToast,
  isLoaded,
  startLassoingCustomers,
  stopLassoingCustomers,
}) {
  // const [openTooltip, setOpenTooltip] = useState(null); // State to manage which tooltip is currently open
  const polygonsRef = useRef({}); // reference to polygon on map created from territory data in DB, needed to update paths
  const lassoPolygonRef = useRef(null);
  const markerClusterRef = useRef(null); // keep track of marker cluster
  const onLoad = useCallback(
    (map) => {
      // set map view to last location
      const { ne, sw } = deltasPointsConverter({ location });
      const bounds = new window.google.maps.LatLngBounds(sw, ne);
      map.fitBounds(bounds);
      setMap(map);
      // add user location to map
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    },
    [location, setMap, setUserLocation]
  );

  // handles adding lasso data to editingMode for display and setting panel visible data
  // const { startLassoingCustomers, stopLassoingCustomers } = useCustomerLasso({
  //   map,
  //   editingMode,
  //   setEditingMode,
  //   setPanelVisible,
  //   customers,
  //   errorToast,
  // });

  // update db with location on unmount
  const onUnmount = useCallback(
    (map) => {
      try {
        const bounds = map.getBounds();
        const { longitudeDelta, latitudeDelta } = deltasPointsConverter({
          ne: bounds.getNorthEast(),
          sw: bounds.getSouthWest(),
        });
        // updat db
        updateDoc(doc(db, 'users', userData.userData?.id), {
          location: { latitude: map.center.lat(), latitudeDelta, longitude: map.center.lng(), longitudeDelta },
        });
        setMap(null);
      } catch (e) {
        console.log('error in MapView unmount: ', e);
      }
    },
    [userData, setMap]
  );

  const updateLocation = debounce(() => {
    if (!map) return;
    const bounds = map.getBounds();
    // console.log('updateLocation bounds: ', bounds);
    const { longitudeDelta, latitudeDelta } = deltasPointsConverter({
      ne: bounds.getNorthEast(),
      sw: bounds.getSouthWest(),
    });
    setLocation({
      latitude: map.center.lat(),
      latitudeDelta,
      longitude: map.center.lng(),
      longitudeDelta,
    });
  }, 200);

  const onTerritoryEdit = ({ territory }) => {
    // locally paths are stored and accepted by react google maps api as "lat" "lng" pairs, in firestore and for use on the mobile app we use coordinates stored as "longitude" "latitude" pairs
    const paths = polygonsRef.current[territory.id].getPath().getArray();

    const coordinates = paths.map((coordinate) => ({
      latitude: coordinate.lat(),
      longitude: coordinate.lng(),
    }));

    // update current territory
    if (editingMode.current) {
      setEditingMode((p) => ({
        ...p,
        current: { ...p.current, coordinates },
      }));
    } else {
      // if there is no current territory add it and a snapshot of the previous so we have an option to remove it later
      setEditingMode((p) => ({
        ...p,
        current: { ...territory, coordinates: coordinates },
        previous: convertTerritoryFormat([territory], 'mobile')[0],
      }));
    }
  };

  const onMapClick = (e) => {
    if (editingMode?.operation === 'addingTerritory') {
      const newCoordinates = [];
      if (editingMode?.current?.coordinates) newCoordinates.push(...editingMode.current.coordinates);
      newCoordinates.push({
        latitude: e.latLng.lat(),
        longitude: e.latLng.lng(),
      });
      setEditingMode((prev) => ({ ...prev, current: { ...prev.current, coordinates: newCoordinates } }));
    } else if (editingMode?.operation === 'selectingCustomers') startLassoingCustomers(e);
  };

  const onMapMouseUp = () => {
    if (editingMode?.operation === 'selectingCustomers') stopLassoingCustomers();
  };

  const markersRef = useRef({});
  // conditions under which to clear markers and or do nothing else
  const updateMarkers = useCallback(() => {
    if (!map || !isLoaded || !!editingMode?.operation || dataToDisplay.type !== 'markers') {
      if (markerClusterRef.current) markerClusterRef.current.clearMarkers();
      return;
    }

    // removing previous markers, if only updating data within them prevent an immediate re-draw (so theres no blink). Save ref for next time
    if (markerClusterRef.current && markers.length && markers.every((marker) => markersRef.current[marker.id])) {
      markerClusterRef.current.clearMarkers(true);
      const cluster = markerClusterRef.current;
      setTimeout(() => cluster.clearMarkers(), 1000); // if markers change before user moves we want to make sure a repaint did occur
    } else if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers();
    }
    markersRef.current = {};
    markers.forEach((marker) => (markersRef.current[marker.id] = marker));

    // format markers for google maps
    const googleMarkers = markers.map((marker) => {
      const googleMarker = new window.google.maps.Marker({
        position: { lat: marker.coordinate.latitude, lng: marker.coordinate.longitude },
        icon: markerSVGs[marker.type.icon],
      });
      googleMarker.addListener('click', () => {
        setPanelVisible({ type: 'marker', data: marker });
      });
      return googleMarker;
    });

    // setup and render custom clusters
    const renderer = {
      render: ({ count, position }) => {
        return new window.google.maps.Marker({
          label: { text: String(count), color: 'white', fontSize: '12px', fontWeight: 'bold' },
          position,
          icon: markerClusterSVG,
          // adjust zIndex to be above other markers
          zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };
    markerClusterRef.current = new MarkerClusterer({ map, markers: googleMarkers, renderer });
  }, [markers, markerClusterRef, map, setPanelVisible, dataToDisplay, editingMode, isLoaded]);

  // create marker clusters and append to map
  useEffect(() => {
    updateMarkers();
  }, [updateMarkers]);

  useEffect(() => {
    return () => {
      if (markerClusterRef.current) markerClusterRef.current.clearMarkers();
    };
  }, []);

  // When adding territories use latitude longitude for mobile -- not lat lng
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
        focusOutline: 'none',
      }}
      // center={goToLocation}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onZoomChanged={updateLocation}
      onDragEnd={updateLocation}
      onCenterChanged={updateLocation}
      onMouseDown={onMapClick}
      onMouseUp={onMapMouseUp}
      options={{
        draggableCursor:
          editingMode.operation === 'addingTerritory' || editingMode.operation === 'selectingCustomers'
            ? 'crosshair'
            : '',
        clickableIcons: false,
        mapTypeId: 'satellite',
        rotateControl: false,
        controlSize: 32,
        draggable: editingMode.operation !== 'selectingCustomers',
      }}
    >
      {/* user location */}
      {userLocation && (
        <Marker
          position={userLocation}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 7,
            fillColor: '#4285F4',
            fillOpacity: 1,
            strokeColor: 'white',
            strokeWeight: 2,
          }}
        />
      )}

      {/* customers  */}
      {dataToDisplay.type === 'customers' &&
        editingMode?.operation !== 'addingTerritory' &&
        editingMode?.operation !== 'editingTerritory' &&
        customers?.map((customer, i) => {
          return (
            <Marker
              key={customer.customerId + i}
              position={{ lat: customer.lat, lng: customer.lng }}
              onClick={() => {
                setPanelVisible({ type: 'customer', data: customer });
              }}
            />
          );
        })}

      {/* adding territory */}
      {editingMode?.operation === 'addingTerritory' &&
        editingMode?.current?.coordinates?.map((point, i) => (
          <MarkerF
            key={'adding-territory-point-' + i}
            position={{ lat: point.latitude, lng: point.longitude }}
          ></MarkerF>
        ))}
      {editingMode?.operation === 'addingTerritory' && editingMode?.current?.coordinates?.length >= 3 && (
        <Polygon
          paths={editingMode?.current?.coordinates.map((coords) => ({ lat: coords.latitude, lng: coords.longitude }))}
          options={{
            editable: false,
            draggable: false,
            clickable: false,
            fillColor: editingMode.current.user.color.value,
            strokeColor: '#000',
            strokeWeight: 1,
          }}
        />
      )}

      {/* selecting multiple customers lasso */}
      {(editingMode?.operation === 'selectingCustomers' || panelVisible?.type === 'customers') &&
        (editingMode?.newCoordinates?.length > 2 ? (
          <Polygon
            options={{
              editable: false,
              draggable: false,
              clickable: false,
            }}
            onLoad={(polygon) => {
              lassoPolygonRef.current = polygon;
            }}
            onMouseUp={() => {
              const paths = lassoPolygonRef.current.getPath().getArray();
              setEditingMode((p) => ({
                ...p,
                newCoordinates: paths.map((el) => ({ latitude: el.lat(), longitude: el.lng() })),
              }));
            }}
            paths={editingMode?.newCoordinates.map((coords) => ({ lat: coords.latitude, lng: coords.longitude }))}
          />
        ) : (
          editingMode?.newCoordinates?.map((point, i) => {
            return (
              <MarkerF
                icon={hollowCircle}
                key={'selecting-point-' + i}
                position={{ lat: point.latitude, lng: point.longitude }}
              ></MarkerF>
            );
          })
        ))}

      {/* territories */}
      {editingMode?.operation !== 'addingTerritory' &&
        editingMode?.operation !== 'selectingCustomers' &&
        territories?.map((territory) => {
          return (
            <Polygon
              key={territory.id}
              id={territory.id}
              paths={territory.coordinates}
              options={{
                fillColor: territory?.user?.color?.value,
                strokeColor: '#000',
                strokeWeight: 1,
                clickable:
                  editingMode?.operation === 'editingTerritory' &&
                  (!editingMode?.current || editingMode?.current?.id === territory?.id),
                editable:
                  editingMode?.operation === 'editingTerritory' &&
                  (!editingMode?.current || editingMode?.current?.id === territory?.id),
                draggable:
                  editingMode?.operation === 'editingTerritory' &&
                  (!editingMode?.current || editingMode?.current?.id === territory?.id),
              }}
              onLoad={(polygon) => (polygonsRef.current[territory.id] = polygon)} // need a ref to this component to capture lat/lng changes
              onMouseUp={() => onTerritoryEdit({ territory })}
            />
          );
        })}

      {/* searched location */}
      {addressSearched?.address && (
        <InfoWindow position={addressSearched.position} onCloseClick={() => setAddressSearched(null)}>
          <>
            <p className='absolute top-[11px] font-medium text-lg'>Searched Location</p>
            <p>{addressSearched?.address}</p>
          </>
        </InfoWindow>
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

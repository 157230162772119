import { CloseModalIconButton } from 'components/NewButtons/CloseModalIconButton';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';

const MessageExample = ({
  exampleText,
  ExampleHeader,
  visible,
  logo = null,
  setSendWithLogo = () => null,
  sendWithLogo = false,
}) => {
  return visible ? (
    <div className='self-start w-full'>
      <div className='flex flex-row items-start justify-center mt-4 mb-2 w-full'>
        <h1 className='text-lg font-bold w-36 flex-shrink-0'>{ExampleHeader}</h1>
        <h1 className='ml-1 -mt-1 py-2 px-3 bg-gray-50 rounded-sm shadow whitespace-pre-wrap break-words text-start text-sm min-h-8 w-full'>
          {sendWithLogo && <img className='mx-auto mb-4' alt='company logo' width={60} height={60} src={logo} />}
          {exampleText}
        </h1>
      </div>
      {logo && (
        <div className='-mt-1 flex items-center justify-center'>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={sendWithLogo} onChange={(e) => setSendWithLogo(e.target.checked)} />}
              label='Include Company Logo'
            />
          </FormGroup>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default function MessageConfirmModal({
  handleSendMassMessage,
  setModal,
  filteredCustomers,
  message,
  typeOfMessage,
  userData,
  sendWithLogo,
  setSendWithLogo,
}) {
  const firstName = filteredCustomers && filteredCustomers[0]?.firstName;
  const lastName = filteredCustomers && filteredCustomers[0]?.lastName;

  const messageExample = message.text
    .replace(/{{\s*firstName\s*}}/gi, firstName)
    .replace(/{{\s*lastName\s*}}/gi, lastName);
  const subjectExample = message.subjectText
    .replace(/{{\s*firstName\s*}}/gi, firstName)
    .replace(/{{\s*lastName\s*}}/gi, lastName);

  return (
    <>
      <div className='modal z-30 flex items-center justify-center'>
        <div className='overlay'></div>
        <div
          // className='note-modal-content'
          className='z-20 max-w-3xl relative'
        >
          <div className='absolute top-3 right-3'>
            <CloseModalIconButton handleCancel={() => setModal(false)} />
          </div>
          <div className='flex flex-col flex-1 items-center text-center lg:col-span-2 bg-white rounded-md shadow-md gap-2 pb-8 px-10 p-4 overflow-y-auto max-h-screen'>
            <div className='flex flex-row items-center gap-2 mt-4 mb-2 ml-2'>
              <h1 className='text-xl font-bold'>
                Confirm this is the message you'd like to send to {filteredCustomers?.length}{' '}
                {filteredCustomers?.length > 1 ? 'customers' : 'customer'}
              </h1>
            </div>
            {/* subject ex */}
            <MessageExample
              exampleText={subjectExample}
              ExampleHeader='Email Subject Example: '
              visible={typeOfMessage === 'email' || typeOfMessage === 'both'}
            />
            {/* email ex */}
            <MessageExample
              exampleText={messageExample}
              ExampleHeader='Email body Example: '
              visible={typeOfMessage === 'email' || typeOfMessage === 'both'}
              logo={userData?.bizData?.logo}
              sendWithLogo={sendWithLogo}
              setSendWithLogo={setSendWithLogo}
            />
            {/* text ex */}
            <MessageExample
              exampleText={messageExample}
              ExampleHeader='Text Message Example: '
              visible={typeOfMessage === 'phoneNumber' || typeOfMessage === 'both'}
            />
            <div className='flex flex-row items-center gap-4 mt-8'>
              <button
                onClick={() => setModal(false)}
                className=' bg-gray-100  text-gray-500  font-bold py-2 px-4 rounded'
              >
                Cancel
              </button>
              <button
                onClick={handleSendMassMessage}
                className=' bg-gray-700 hover:bg-gray-800 text-green-200  font-bold py-2 px-4 rounded'
              >
                Confirm Send
              </button>
            </div>
            {typeOfMessage !== 'email' && (
              <div className='flex flex-row items-center gap-4 mt-8 text-gray-600 text-xs'>
                <p>(Text messages will be sent in batches of 8 every 2 minutes to avoid rate limiting.)</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
